<div class="lib-content-wrapper">
  <lib-title-actions-toolbar title="Notebooks" [buttons]="buttons" i18n-title>
    <lib-namespace-select
      *ngIf="(ns.dashboardConnected$ | async) === dashboardDisconnectedState"
      namespacesUrl="/api/namespaces"
    ></lib-namespace-select>
  </lib-title-actions-toolbar>

  <!--scrollable page content-->
  <div class="page-padding lib-flex-grow lib-overflow-auto">
    <lib-table
      [config]="config"
      [data]="processedData"
      [trackByFn]="notebookTrackByFn"
      (actionsEmitter)="reactToAction($event)"
    ></lib-table>
  </div>
</div>
