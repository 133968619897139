<div class="flex">
  <div
    class="threadline"
    (click)="toggleClicked()"
    matTooltip="Hide"
    i18n-matTooltip
    matTooltipPosition="right"
  ></div>

  <div class="wide">
    <div class="options-wrapper anchor" #options>
      <ng-content></ng-content>
    </div>
  </div>
</div>

<div class="anchor">
  <button
    class="toggle-button button-with-icon"
    color="primary"
    mat-button
    (click)="toggleClicked()"
    type="button"
  >
    <lib-icon class="blue" [icon]="buttonIcon"></lib-icon>
    {{ text }}
  </button>
</div>
