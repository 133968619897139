<div class="lib-content-wrapper">
  <lib-title-actions-toolbar
    title="New notebook"
    [backButton]="true"
    (back)="onCancel()"
  >
  </lib-title-actions-toolbar>

  <!--scrollable page content-->
  <div class="page-padding lib-flex-grow lib-overflow-auto">
    <form
      novalidate
      class="form"
      (ngSubmit)="onSubmit()"
      [formGroup]="formCtrl"
    >
      <app-form-name [parentForm]="formCtrl"></app-form-name>

      <app-form-image
        [parentForm]="formCtrl"
        [images]="config?.image?.options"
        [imagesGroupOne]="config?.imageGroupOne?.options"
        [imagesGroupTwo]="config?.imageGroupTwo?.options"
        [allowCustomImage]="config?.allowCustomImage"
        [hideRegistry]="config?.hideRegistry"
        [hideTag]="config?.hideTag"
      ></app-form-image>

      <app-form-cpu-ram
        [parentForm]="formCtrl"
        [readonlyCPU]="config?.cpu?.readOnly"
        [readonlyMemory]="config?.memory?.readOnly"
        [cpuLimitFactor]="config?.cpu?.limitFactor"
        [memoryLimitFactor]="config?.memory?.limitFactor"
      ></app-form-cpu-ram>

      <app-form-gpus
        [parentForm]="formCtrl"
        [vendors]="config?.gpus?.value.vendors"
      ></app-form-gpus>

      <app-form-workspace-volume
        *ngIf="formCtrl.get('workspace')"
        [readonly]="config?.workspaceVolume?.readOnly"
        [volGroup]="formCtrl.get('workspace')"
        [externalName]="formCtrl.get('name').value"
      >
      </app-form-workspace-volume>

      <app-form-data-volumes
        [volsArray]="formCtrl.get('datavols')"
        [readonly]="config?.dataVolumes?.readOnly"
        [externalName]="formCtrl.get('name').value"
      >
      </app-form-data-volumes>

      <lib-advanced-options>
        <app-form-configurations
          [parentForm]="formCtrl"
        ></app-form-configurations>

        <app-form-affinity-tolerations
          [parentForm]="formCtrl"
          [affinityConfigs]="config?.affinityConfig?.options"
          [tolerationGroups]="config?.tolerationGroup?.options"
        ></app-form-affinity-tolerations>

        <app-form-advanced-options
          [parentForm]="formCtrl"
        ></app-form-advanced-options>
      </lib-advanced-options>
    </form>

    <div class="form-buttons">
      <div
        [matTooltip]="setTooltipText(formCtrl)"
        [matTooltipDisabled]="formCtrl.valid"
      >
        <button
          mat-raised-button
          color="primary"
          (click)="onSubmit()"
          [disabled]="!formCtrl.valid"
          i18n
        >
          LAUNCH
        </button>
      </div>

      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
        i18n
      >
        CANCEL
      </button>
    </div>
  </div>
</div>
