<div
  [libPopover]="timeTpl"
  [libPopoverPosition]="popoverPosition"
  [libPopoverDisabled]="isPopoverDisabled"
  [libPopoverHideDelay]="100"
  [libPopoverShowDelay]="100"
  class="truncate"
>
  {{ formattedDate }}
</div>

<ng-template #timeTpl>
  <lib-details-list-item key="Local" [bottomDivider]="false" keyMinWidth="50px">
    {{ date | libToDate }}
  </lib-details-list-item>

  <lib-details-list-item key="UTC" [bottomDivider]="false" keyMinWidth="50px">
    {{ date }}
  </lib-details-list-item>
</ng-template>
