<!--Ready Phase-->
<button
  *ngIf="isPhaseReady()"
  mat-icon-button
  matTooltip="{{ action.tooltipReady }}"
  matTooltipClass="custom-tooltip"
  [color]="action.color"
  (click)="emitClickedEvent()"
>
  <lib-icon [icon]="action.iconReady"></lib-icon>
</button>

<!--Init Phase-->
<button
  *ngIf="isPhaseInit()"
  mat-icon-button
  matTooltip="{{ action.tooltipInit }}"
  matTooltipClass="custom-tooltip"
  [color]="action.color"
  (click)="emitClickedEvent()"
>
  <lib-icon [icon]="action.iconInit"></lib-icon>
</button>

<mat-spinner *ngIf="isPhaseWaiting()" diameter="20"></mat-spinner>

<button *ngIf="isPhaseDisabled()" mat-icon-button disabled>
  <lib-icon [icon]="action.iconInit"></lib-icon>
</button>
