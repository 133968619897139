<ng-container *ngIf="hasVisibleItems(element)">
  {{ valueDescriptor.noValueText }}
</ng-container>

<mat-chip-list class="chip-list-wa">
  <mat-chip
    *ngFor="let chip of getVisibleChips(element); trackBy: trackByFn"
    [color]="chip.color"
    [matTooltip]="chip.tooltip"
    matTooltipClass="custom-tooltip"
    class="list-chip"
  >
    {{ chip.value }}
  </mat-chip>
</mat-chip-list>
