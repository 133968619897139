<ng-container [ngSwitch]="phase">
  <ng-template [ngSwitchCase]="STATUS_TYPE.WAITING">
    <ng-container *ngTemplateOutlet="spinnerTpl"></ng-container>
  </ng-template>

  <ng-template [ngSwitchCase]="STATUS_TYPE.TERMINATING">
    <ng-container *ngTemplateOutlet="spinnerTpl"></ng-container>
  </ng-template>

  <lib-icon
    *ngSwitchCase="STATUS_TYPE.STOPPED"
    icon="custom:stoppedResource"
    class="align-middle"
  ></lib-icon>

  <mat-icon *ngSwitchDefault [ngClass]="statusIcon" class="align-middle">
    {{ statusIcon }}
  </mat-icon>
</ng-container>

<ng-template #spinnerTpl>
  <mat-spinner diameter="24" class="align-middle"></mat-spinner>
</ng-template>
