<mat-form-field appearance="outline" class="wide">
  <mat-label i18n>Size in Gi</mat-label>
  <input
    autocomplete="off"
    matInput
    type="number"
    min="1"
    [formControl]="sizeNum"
    data-cy="size input"
  />
</mat-form-field>
