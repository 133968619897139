<span
  [libPopover]="helperTpl"
  [libPopoverPosition]="popoverPosition"
  [libPopoverHideDelay]="100"
  [libPopoverShowDelay]="100"
  class="truncate"
>
  <mat-icon>help</mat-icon>
</span>

<ng-template #helperTpl>
  <div class="mat-body helper-text">
    <p>
      Create a filter query by entering either a property name or just a value
      to narrow your results.
    </p>
    <p>
      Example:
      <mat-chip class="mat-chip-blue">
        Name: filterValue
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </p>
    <p>
      Filter empty values by entering double quotes:
      <mat-chip class="mat-chip-blue">
        Name: ""
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </p>
    <mat-divider></mat-divider>
    <ng-template [ngIf]="showStatus">
      <p><b>Status</b></p>
      <p>Filter based on the objects' status, by using:</p>
      <ul>
        <li>The value shown in the tooltip</li>
        <li>
          The status types (i.e.
          <i
            >ready, waiting, warning, error, unavailable, uninitialized,
            terminating, stopped</i
          >)
        </li>
      </ul>
    </ng-template>
    <mat-divider></mat-divider>
    <ng-template [ngIf]="showDate">
      <p><b>Date</b></p>
      <p>Filter timestamp values based on:</p>
      <ul>
        <li>
          <span
            >The "-" character for empty date values:
            <mat-chip class="mat-chip-blue">
              Created at: -
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </span>
        </li>
        <li>
          The relative time text, shown in the column (i.e. <i>X months ago</i>)
        </li>
        <li>
          The substring of the timestamp, that is shown in the UTC part of the
          tooltip
        </li>
      </ul>
    </ng-template>
  </div>
</ng-template>
