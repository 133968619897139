<mat-form-field appearance="outline" class="wide">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    type="number"
    [min]="min"
    [step]="step"
    [formControl]="sizeControl"
  />
  <mat-error *ngIf="sizeControl.hasError('min')" i18n>
    Cannot be less than {{ min }}.
  </mat-error>
</mat-form-field>
