<!--A K8s PVC spec might have either a metadata.name of metadata.generateName-->
<!--field. The form should be able to handle both cases-->

<mat-form-field
  appearance="outline"
  class="wide"
  *ngIf="metadataGroup.get('name')"
>
  <mat-label i18n>Name</mat-label>
  <input
    autocomplete="off"
    matInput
    [formControl]="metadataGroup.get('name')"
    data-cy="volume name input"
  />
</mat-form-field>

<mat-form-field
  appearance="outline"
  class="wide"
  *ngIf="metadataGroup.get('generateName')"
>
  <mat-label i18n>Name (suffix)</mat-label>
  <input
    autocomplete="off"
    matInput
    [formControl]="metadataGroup.get('generateName')"
    data-cy="volume generateName input"
  />
  <mat-icon
    matSuffix
    class="info-icon"
    matTooltip="The cluster will append a random suffix to this name"
    i18n-matTooltip
    >info</mat-icon
  >
</mat-form-field>
