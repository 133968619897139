<lib-form-section title="Affinity / Tolerations" i18n-title>
  <div class="row">
    <!--Affinity-->
    <mat-form-field class="wide column" appearance="outline">
      <mat-label i18n> Affinity Config </mat-label>
      <mat-select [formControl]="parentForm.get('affinityConfig')">
        <mat-option value="" i18n="option None">None</mat-option>
        <mat-option
          *ngFor="let affinityConfig of affinityConfigs"
          [value]="affinityConfig.configKey"
        >
          {{ affinityConfig.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!--Tolerations-->
    <mat-form-field class="wide column" appearance="outline">
      <mat-label i18n> Tolerations Group </mat-label>
      <mat-select [formControl]="parentForm.get('tolerationGroup')">
        <mat-option value="" i18n="option None">None</mat-option>
        <mat-option
          *ngFor="let tolerationGroup of tolerationGroups"
          [value]="tolerationGroup.groupKey"
        >
          {{ tolerationGroup.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</lib-form-section>
