<div class="flex">
  <div class="page-padding-left"></div>

  <button
    *ngIf="backButton"
    mat-icon-button
    class="back-button"
    (click)="emitBack()"
  >
    <mat-icon>arrow_back</mat-icon>
  </button>

  <div class="mat-headline title-margin" data-cy-toolbar-title>
    {{ title }}
  </div>

  <div class="margin-content">
    <ng-content></ng-content>
  </div>

  <div class="toolbar-buttons">
    <ng-container *ngFor="let button of buttons">
      <!--raised button-->
      <ng-container *ngIf="button.raised">
        <div [matTooltip]="button.tooltip">
          <button
            mat-button
            [color]="button.color"
            [disabled]="button.disabled"
            (click)="button.fn()"
            class="toolbar-button"
            [attr.data-cy-toolbar-button]="button.text"
          >
            <mat-icon *ngIf="button.icon" class="button-icon">
              {{ button.icon }}
            </mat-icon>
            {{ button.text }}
          </button>
        </div>
      </ng-container>

      <!--stroked button-->
      <ng-container *ngIf="button.stroked">
        <div [matTooltip]="button.tooltip">
          <button
            mat-stroked-button
            [color]="button.color"
            [disabled]="button.disabled"
            (click)="button.fn()"
            class="toolbar-button"
            [attr.data-cy-toolbar-button]="button.text"
          >
            <mat-icon *ngIf="button.icon" class="button-icon">
              {{ button.icon }}
            </mat-icon>
            {{ button.text }}
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<mat-divider class="page-placement margin-top"></mat-divider>
