<!--TODO(kimwnasptd): we shouldn't add any padding in the top at all in here-->
<!--if a page would like to add some padding then it should be its own-->
<!--responsibility.-->
<div class="center-flex" [class.space-top]="tableTheme === TABLE_THEME.CARD">
  <!--CARD table theme-->
  <mat-card
    *ngIf="tableTheme === TABLE_THEME.CARD"
    class="mat-elevation-z4"
    [style.width]="totalWidth"
    [style.min-width]="minTableWidth"
  >
    <div class="header card-title-padding">
      <div data-cy-table-title>
        {{ config?.title }}
      </div>

      <button
        *ngIf="config?.newButtonText"
        mat-button
        id="newResource"
        color="primary"
        class="right"
        (click)="newButtonTriggered()"
        data-cy-resource-table-new
      >
        <mat-icon class="new-resource-button">add</mat-icon>
        {{ config?.newButtonText }}
      </button>
    </div>

    <mat-divider></mat-divider>

    <mat-card-content>
      <!-- Iterate on the column definitions and create the Columns-->
      <lib-table
        [config]="config"
        [data]="data"
        [trackByFn]="trackByFn"
        (actionsEmitter)="actionTriggered($event)"
      ></lib-table>
    </mat-card-content>
  </mat-card>

  <!--FLAT table theme-->
  <div
    [style.width]="totalWidth"
    [style.min-width]="minTableWidth"
    *ngIf="tableTheme === TABLE_THEME.FLAT"
  >
    <div class="header">
      <p>{{ config?.title }}</p>

      <button
        *ngIf="config?.newButtonText"
        mat-button
        id="newResource"
        color="primary"
        class="right"
        (click)="newButtonTriggered()"
        data-cy-resource-table-new
      >
        <mat-icon class="new-resource-button">add</mat-icon>
        {{ config?.newButtonText }}
      </button>
    </div>

    <mat-divider></mat-divider>

    <!-- Iterate on the column definitions and create the Columns-->
    <lib-table
      [config]="config"
      [data]="data"
      [trackByFn]="trackByFn"
      (actionsEmitter)="actionTriggered($event)"
    ></lib-table>
  </div>
</div>
