<mat-icon
  *ngIf="notebookServerType === 'jupyter'"
  svgIcon="jupyterlab-icon"
  aria-hidden="false"
  aria-label="JupyterLab based server"
></mat-icon>
<mat-icon
  *ngIf="notebookServerType === 'group-one'"
  svgIcon="group-one-icon"
  aria-hidden="false"
  aria-label="Group One based server"
></mat-icon>
<mat-icon
  *ngIf="notebookServerType === 'group-two'"
  svgIcon="group-two-icon"
  aria-hidden="false"
  aria-label="Group Two based server"
></mat-icon>
