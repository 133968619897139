<mat-divider *ngIf="topDivider"></mat-divider>

<div class="list-entry-row">
  <!--key-->
  <div
    class="list-entry-key vertical-align"
    [matTooltip]="keyTooltip"
    [style.min-width]="keyMinWidth"
  >
    {{ key }}
  </div>

  <!--value-->
  <div class="list-entry-value" [matTooltip]="valueTooltip">
    <ng-container *ngIf="chipsList && chipsList.length > 0">
      <mat-chip-list class="chip-list-wa">
        <mat-chip
          *ngFor="let chip of chipsList"
          [color]="chip.color"
          [matTooltip]="chip.tooltip"
          matTooltipClass="custom-tooltip"
          class="list-chip"
        >
          {{ chip.value }}
        </mat-chip>
      </mat-chip-list>
    </ng-container>

    <div *ngIf="value" class="flex">
      <div class="icon" *ngIf="icon">
        <mat-icon [inline]="true" [ngClass]="getClasses()">
          {{ icon }}
        </mat-icon>
      </div>

      {{ value }}

      <div class="icon" *ngIf="copyValue">
        <mat-icon
          class="copy-button key-icon"
          matRipple
          matRippleRadius="16"
          [matRippleCentered]="true"
          [inline]="true"
          (click)="copy()"
        >
          content_copy
        </mat-icon>
      </div>
    </div>

    <div *ngIf="!value" class="flex">
      <div class="icon" *ngIf="icon">
        <mat-icon [inline]="true" [ngClass]="getClasses()">
          {{ icon }}
        </mat-icon>
      </div>

      <ng-content class="vertical-align"> </ng-content>

      <div class="icon" *ngIf="copyValue">
        <mat-icon
          class="copy-button key-icon"
          matRipple
          matRippleRadius="16"
          [matRippleCentered]="true"
          [inline]="true"
          (click)="copy()"
        >
          content_copy
        </mat-icon>
      </div>
    </div>
  </div>
</div>

<mat-divider *ngIf="bottomDivider"></mat-divider>
