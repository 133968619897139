<div class="lib-content-wrapper details-page">
  <lib-title-actions-toolbar
    title="Notebook details"
    [buttons]="buttonsConfig"
    [backButton]="true"
    (back)="navigateBack()"
  >
  </lib-title-actions-toolbar>

  <div class="details-page-outer">
    <lib-loading-spinner *ngIf="!notebookInfoLoaded"></lib-loading-spinner>

    <ng-container *ngIf="notebookInfoLoaded">
      <div class="details-page-inner">
        <div class="details-page-inner-2">
          <lib-status-icon
            class="small-padding-right"
            [phase]="status.phase"
          ></lib-status-icon>
          <div class="mat-title">{{ notebookName }}</div>
        </div>
        <div class="small-padding-up">
          <lib-status-info [status]="status"></lib-status-info>
        </div>
      </div>
      <mat-tab-group
        dynamicHeight
        animationDuration="0ms"
        (selectedTabChange)="onTabChange($event)"
        [selectedIndex]="selectedTab.index"
      >
        <mat-tab label="OVERVIEW">
          <ng-template matTabContent>
            <app-overview
              *ngIf="notebookInfoLoaded"
              [notebook]="notebook"
              [pod]="notebookPod"
              [podRequestCompleted]="podRequestCompleted"
              [notebookStatus]="status"
            ></app-overview>
          </ng-template>
        </mat-tab>

        <mat-tab label="LOGS">
          <ng-template matTabContent>
            <app-logs
              [pod]="notebookPod"
              [podRequestCompleted]="podRequestCompleted"
            ></app-logs>
          </ng-template>
        </mat-tab>

        <mat-tab label="EVENTS">
          <ng-template matTabContent>
            <app-events [notebook]="notebook"></app-events>
          </ng-template>
        </mat-tab>

        <mat-tab label="YAML">
          <ng-template matTabContent>
            <app-yaml
              [podRequestCompleted]="podRequestCompleted"
              [notebook]="notebook"
              [pod]="notebookPod"
            ></app-yaml>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </div>
</div>
