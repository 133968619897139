<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p>{{ data.message }}</p>

  <p class="error">{{ data.error }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="DIALOG_RESP.CANCEL" cdkFocusInitial>
    {{ data.cancel.toUpperCase() }}
  </button>

  <button
    *ngIf="!isApplying"
    mat-button
    (click)="onAcceptClicked()"
    [color]="data.confirmColor"
  >
    {{ data.accept.toUpperCase() }}
  </button>

  <button *ngIf="isApplying" mat-button disabled>
    <div class="waiting-button-wrapper">
      <mat-spinner diameter="16"></mat-spinner>
      <p>{{ data.applying }}</p>
    </div>
  </button>
</div>
