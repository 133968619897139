<!-- Namespaces Selector -->
<div class="center-flex space-top">
  <mat-form-field>
    <mat-label i18n>Select Namespace</mat-label>
    <mat-select
      name="namespacesSelect"
      [value]="currNamespace"
      (selectionChange)="namespaceChanged($event.value)"
      data-cy-namespace-selector-dropdown
    >
      <mat-option data-cy-all-namespaces value="{all}">
        All namespaces
      </mat-option>

      <mat-option
        *ngFor="let namespace of namespaces"
        [value]="namespace"
        attr.data-cy-namespace="{{ namespace }}"
      >
        {{ namespace }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="spacer"></div>
</div>
